<template>
  <v-container
    v-if="pageLoading"
    class="fluid d-flex justify-center align-center loading-container"
  >
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </v-container>
  <div v-else>
    <v-row class="welcome-container mb-12 ml-12 pa-0">
      <v-col class="mr-6 pa-0">
        <!-- <p class="textDark--text font-weight-light title">WELCOME CARD</p> -->

        <p class="textOrange--text title">Edit Welcome Card</p>

        <v-row no-gutters>
          <v-col class="d-flex">
            <welcome-card
              @cardMedia="updatedCardMedia"
              @cardTitle="updatedCardTitle"
              :cardData="childData"
              @cardDescription="updatedCardDescription"
              v-on:formInvalid="setDisabled($event)"
            />

            <welcome-card-links @cardLinks="updateCardlinks" :cardLinks="childLinks" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="ma-0 pa-0">
        <!-- <p class="textDark--text font-weight-light title">PREVIEW BEEZ CARD</p> -->
        <p class="title textOrange--text">Preview BeezCard</p>

        <welcome-card-preview :cardData="childData" :cardLinks="childLinks" />
      </v-col>
      <bz-notification v-model="notification"></bz-notification>
    </v-row>
    <v-footer height="65" absolute class="d-flex justify-center">
      <div class="d-flex flex-row">
        <p class="body-1 textDark--text mr-12 my-2">Click on Save Button to Save changes</p>
        <BzButton
          title="Save Changes"
          depressed
          icon="mdi-content-save"
          type="Submit"
          :loading="loading"
          width="240"
          @click="generateWelcomeCard"
          :disabled="isDisabled"
        >
          <v-divider vertical color="white" class="ml-4 mr-6" />
        </BzButton>
      </div>
    </v-footer>
  </div>
</template>

<script>
import WelcomeCard from "./WelcomeCard.vue";
import WelcomeCardPreview from "./WelcomeCardPreview.vue";
import WelcomeCardLinks from "./WelcomeCardLinks.vue";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BzButton from "@shared/components/BzSubmitButton";
import BzNotification from "@/shared/components/BzNotification";

export default {
  name: "WelcomeCardView",
  components: {
    WelcomeCardPreview,
    WelcomeCard,
    WelcomeCardLinks,
    BzNotification,
    BzButton
  },
  data: function() {
    return {
      new: true,
      childData: {
        title: "",
        welcomeCardValue: "",
        description: "",
        mediaId: "",
        mediaType: "",
        mediaUrl: ""
      },
      childLinks: {
        link1: "",
        link2: "",
        link3: ""
      },
      isDisabled: true,
      cards: [],
      type: "",
      loading: false,
      pageLoading: false
    };
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("admin", ["currentModule"]),
    ...mapFields("admin", [
      "accountModules",
      "elevationModule",
      "configurationsIds",
      "onBoardingModules"
    ])
  },

  beforeDestroy() {},
  async created() {
    this.pageLoading = true;

    let accountId = await this.currentUser.accounts[0];

    this.cards = await this.getWelcomeCard();

    if (this.$router.currentRoute.name === "welcome-card") {
      this.cards.length > 0 && (this.new = false);
    } else {
      this.cards.length > 1 && (this.new = false);
    }

    this.cards.length === 2 && (this.new = false);

    if (this.$router.currentRoute.name === "welcome-card") {
      this.card = this.cards[0];
    } else {
      this.card = this.cards[1];
    }

    if (this.card) {
      let { title, description, links, photoId, file } = this.card;

      let image = null;
      try {
        image = await this.fetchFilesUploaded({ logoId: photoId });
      } catch (error) {}

      this.childData.title = title;
      this.childData.description = description;
      this.childData.mediaUrl = file ? file.url : null;
      this.childData.mediaType = file ? "image" : null;
      this.childData.mediaId = photoId;
      this.childLinks.link1 = links[0];
      this.childLinks.link2 = links[1];
      this.childLinks.link3 = links[2];
    }
    this.pageLoading = false;
  },
  // async beforeRouteLeave(to, from, next) {
  //   if (false) {
  //     let answer = await this.handleDialog();
  //     if (answer) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    ...mapActions("welcome", {
      patchWelcomeCard: "patchWelcomeCard",
      createCard: "createWelcomeCard",
      getWelcomeCard: "getWelcomeCard"
    }),
    ...mapActions("admin", {
      fetchFilesUploaded: "fetchFilesUploaded"
    }),

    updatedCardMedia(variable) {
      this.childData.mediaId = variable.id;
      this.childData.mediaType = variable.type;
      this.childData.mediaUrl = variable.url;
    },
    updatedCardTitle(variable) {
      this.childData.title = variable;
    },
    updatedCardDescription(variable) {
      this.childData.description = variable;
    },

    updateCardlinks(variable) {
      this.childLinks = variable;
    },
    async handleDialog() {
      let res = await this.$dialog.confirm({
        title: "Confirmation",
        text: "Are you sure you'd like to leave without saving changes?",
        persistent: true
      });
      return res;
    },
    setDisabled(value) {
      this.isDisabled = value;
    },
    // checkIfChanges() {
    //   if (this.cards) {
    //     let { title, description, links, photoId, file } = this.cards;
    //     if (
    //       this.childData.title !== title ||
    //       this.childData.description !== description ||
    //       this.childLinks.link1 !== links[0] ||
    //       this.childLinks.link2 !== links[1] ||
    //       this.childLinks.link3 !== links[2]
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
    async generateWelcomeCard() {
      this.loading = true;

      let { title, description, mediaId } = this.childData;
      let { link1, link2, link3 } = this.childLinks;

      if (this.$router.currentRoute.name === "welcome-card") {
        this.type = "welcome-card";
      } else {
        this.type = "elevation-welcome-card";
      }

      if (this.new) {
        const card = await this.createCard({
          photoId: mediaId,
          title: title,
          description: description,
          links: [link1, link2, link3],
          type: this.type
        });

        // const cards = await this.getWelcomeCard();

        this.new = false;

        this.notify("success", "Your changes have been sucessfully saved");
        this.loading = false;
      } else {
        try {
          await this.patchWelcomeCard({
            photoId: mediaId || undefined,
            title: title,
            description: description,
            links: [link1, link2, link3],
            type: this.type
          });

          this.notify("success", "Your changes have been sucessfully saved");
          this.loading = false;
        } catch (error) {
          // this.notify("error", "An unexpected error has occured");
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.welcome-container {
  min-height: 60vh;
}
.loading-container {
  min-height: 60vh;
}
</style>

<template>
  <v-text-field color="primaryLight" :label="label">
    <v-icon slot="append">mdi-pencil</v-icon>
  </v-text-field>
</template>

<script>
export default {
  name: "BzLinkInput",
  props: ["label"]
};
</script>
<template>
  <div>
    <v-btn
      class="text-capitalize elevation-0"
      small
      color="primaryLight"
      dark
      @click="dialog = true"
    >
      <v-icon class="mr-2">mdi-folder-multiple-image</v-icon>Beez Gallery
    </v-btn>
    <v-dialog max-width="650" v-model="dialog" content-class="ma-2" scrollable>
      <v-card class="white">
        <h1 class="text-center text-uppercase title secondaryText--text">
          beez gallery
        </h1>
        <v-card-text class="pb-3 px-1">
          <v-item-group active-class="selected" v-model="selected">
            <v-row class="px-md-1 ma-0" width>
              <v-col
                cols="4"
                md="3"
                lg="2"
                class="pa-1"
                v-for="(image, i) in gallery"
                :key="i"
              >
                <v-item v-slot:default="{ active, toggle }">
                  <v-img
                    :src="image.file.url"
                    height="100%"
                    width="100%"
                    @click="toggle"
                    class="image"
                  ></v-img>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card-text>
        <v-card-actions class="mx-0">
          <v-btn
            color="primaryLight elevation-0 px-12 white--text"
            @click="selectImage"
            :disabled="!selected"
            class="ma-auto"
            >Select</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["value"],
  async beforeMount() {
    const { data } = await this.getGalleryContent();
    this.gallery = data;
  },
  data: () => ({
    dialog: false,
    gallery: [],
    selected: ""
  }),
  methods: {
    ...mapActions("beezGallery", { getGalleryContent: "getGalleryContent" }),
    selectImage() {
      this.$emit("input", this.gallery[this.selected]);
      this.dialog = false;
    }
  }
};
</script>
<style>
.selected {
  filter: drop-shadow(2px 4px 6px black);
}
.image:hover {
  cursor: pointer;
  filter: drop-shadow(2px 4px 6px black);
  transition: 200ms;
}
</style>

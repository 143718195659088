<template>
  <v-card width="300" height="450" class="d-flex align-center flex-column links-card">
    <p class="mt-4 mb-0 text primaryLight--text title">LINKS (OPTIONAL)</p>
    <div>
      <v-text-field
        prefix="https://"
        color="primaryLight"
        v-model="cardLinks.link1"
        :rules="linkRules"
        type="url"
        @keyup="send"
        label="Link 1"
      >
        <v-icon slot="append">mdi-pencil</v-icon>
      </v-text-field>
      <v-text-field
        prefix="https://"
        v-model="cardLinks.link2"
        @keyup="send"
        color="primaryLight"
        label="Link 2"
        :rules="linkRules"
      >
        <v-icon slot="append">mdi-pencil</v-icon>
      </v-text-field>
      <v-text-field
        prefix="https://"
        v-model="cardLinks.link3"
        @keyup="send"
        color="primaryLight"
        label="Link 3"
        :rules="linkRules"
      >
        <v-icon slot="append">mdi-pencil</v-icon>
      </v-text-field>
    </div>
  </v-card>
</template>

<script>
import BzLinkInput from "../../../components/BzLinkInput";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "WelcomeCardLinks",
  components: {
    BzLinkInput
  },
  props: ["cardLinks"],
  computed: {
    ...mapFields("auth", ["currentUser"])
  },
  data() {
    return {
      linkRules: [
        v => v.includes("www.") || "Enter a valid link",
        v => !v.includes("https://") || "Enter a valid link"
      ]
    };
  },
  async created() {
    let accountId = await this.currentUser.accounts[0];
  },
  methods: {
    send: function() {
      this.$emit("cardLinks", this.cardLinks);
    }
  }
};
</script>

<style scoped>
.links-card {
  box-shadow: 0px 0px 75px #00000024;
}
</style>

<template>
  <v-card class="links-card" width="350" height="450">
    <v-row no-gutters class="d-flex flex-column fill-height" color="grey">
      <v-col class="image-container grey d-flex justify-center align-center">
        <v-row class="secondaryText ma-0 pa-0">
          <v-col
            class="ml-8 mt-8 pa-0 d-flex flex-column align-self-start text-light"
            lg="3"
            md="3"
            cols="8"
          >
            <!-- <v-img class="pa-0" v-if="imageUrl" :src="imageUrl"></v-img> -->
            <div class="file-container">
              <v-img class="pa-0" src="../../../../assets/add_image_black.svg"></v-img>
            </div>
          </v-col>

          <v-col
            lg="8"
            md="8"
            cols="12"
            class="ma-0 ml-8 pa-0 pt-2 textLight--text d-flex flex-column align-self-start"
          >
            WELCOMING MEDIA
            <br />GOES HERE
          </v-col>
        </v-row>
      </v-col>
      <v-col class="description-container">
        <div>
          <div class="d-flex flex-row"></div>

          <div class="text-container ml-3">
            <div class="d-flex">
              <beez-gallery @input="selectImage" class="pa-2 mt-5" />
              <label class="custom-file-upload">
                <input type="file" id="logo" accept="image/*, video/*" ref="onfile" />
              </label>
              <bz-button
                :loading="imageLoading"
                title="Upload"
                @click="$refs.onfile.click()"
                small
                icon="mdi-devices"
                class="mt-7 ml-8 elevation-0"
                width="110"
                color="#444444"
              />
            </div>
            <div class="mt-5">
              <!-- :rules="requiredRules" -->

              <v-text-field
                light
                width="2"
                :maxlength="maxTitle"
                :counter="maxTitle"
                color="primaryLight"
                v-model="cardData.title"
                @keyup="sendTitle"
                placeholder="Write Welcome Title"
              ></v-text-field>
              <v-text-field
                color="primaryLight"
                v-model="cardData.description"
                @keyup="sendDescription"
                :maxlength="maxDescription"
                :counter="maxDescription"
                placeholder="Write a short message"
              ></v-text-field>
            </div>
          </div>
        </div>
      </v-col>
      <avatar-cropper
        v-show="showCropper"
        @changed="handleChange"
        :trigger="avatarTrigger"
        :labels="avatarCropper.labels"
        :cropper-options="avatarCropper.options"
        :output-mime="avatarCropper.mime"
        :upload-handler="uploadAvatar"
      />
    </v-row>
  </v-card>
</template>

<script>
import BzButton from "@shared/components/BzSubmitButton";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BeezGallery from "../components/BeezGallery";
import { required } from "vuelidate/lib/validators";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "WelcomeCard",
  components: {
    BeezGallery,
    BzButton,
    AvatarCropper
  },
  props: ["cardData"],
  data() {
    return {
      image: null,
      imageLoading: false,
      maxTitle: 25,
      maxDescription: 64,
      requiredRules: [v => !!v || "Field is required"],
      avatarBlob: null,
      showCropper: false,
      correctFormat: false,
      selectedAvatarImage: null,
      avatarTrigger: "#logo",
      avatarCropper: {
        labels: {
          submit: "Done",
          cancel: "Cancel"
        },
        options: {
          aspectRatio: 2.035
        },
        mime: "image/jpeg"
      }
    };
  },
  computed: {
    ...mapFields("auth", ["currentUser"])
  },
  async created() {
    let accountId = await this.currentUser.accounts[0];
    // this.cards = await this.getWelcomeCard({
    //   accountId: accountId
    // });

    if (this.cardData) {
      this.cardData.title = this.cardData.title;
      this.cardData.description = this.cardData.description;
      if (this.cardData.title !== "" && this.cardData.description !== "") {
        this.$emit("formInvalid", false);
      }
    }
  },
  validations: {
    cardData: {
      title: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    ...mapActions("welcome", {
      filesUpload: "filesUpload"
    }),
    handleChange(file) {
      if (file.type.split("/")[0] == "image") {
        // this.notify("error", "An unexpected error has occured");

        this.image = file;
        this.correctFormat = true;
        this.showCropper = true;
        return;
      } else {
        this.correctFormat = false;
        this.showCropper = false;
        this.onfilepicked(file);
      }
    },
    async uploadFile() {
      try {
        const [{ _id, url, contentType }] = await this.filesUpload({
          fileData: this.avatarBlob
        });
        this.cardData.mediaId = _id;
        this.cardData.mediaType = contentType;
        this.cardData.mediaUrl = url;
        this.imageLoading = false;
        this.$emit("formInvalid", false);
      } catch (err) {
        console.log(err);
      }
    },
    uploadAvatar(file) {
      if (this.correctFormat) {
        this.imageLoading = true;
        this.$emit("formInvalid", true);

        file.getCroppedCanvas().toBlob(blob => {
          if (blob !== null) {
            this.avatarBlob = blob;
            this.uploadFile();
          }
        });

        this.selectedAvatarImage = file
          .getCroppedCanvas()
          .toDataURL(this.avatarCropper.mime);
      } else {
        return;
      }
    },
    selectImage(variable) {
      this.cardData.media = {
        id: variable.fileId,
        type: variable.file.contentType,
        url: variable.file.url
      };
      this.$emit("cardMedia", {
        id: this.cardData.mediaId,
        type: this.cardData.mediaType,
        url: this.cardData.mediaUrl
      });
    },
    send: function() {
      this.$emit("cardData", this.cardData);
    },
    sendFile: function() {
      this.$emit("cardMedia", {
        id: this.cardData.mediaId,
        type: this.cardData.mediaType,
        url: this.cardData.mediaUrl
      });
    },
    sendTitle: function() {
      this.$emit("cardTitle", this.cardData.title);
    },
    sendDescription: function() {
      this.$emit("cardDescription", this.cardData.description);
    },
    ...mapActions("welcome", {
      getWelcomeCard: "getWelcomeCard"
    }),
    async onfilepicked(file) {
      this.imageLoading = true;
      // const file = event.target.files[0];

      /* Type Check if the Data is not an image results in an Error*/
      if (
        !(
          file.type.split("/")[0] == "image" ||
          file.type.split("/")[0] == "video"
        )
      ) {
        // alert("Please enter a correct format Images only");
        return;
      }
      const filereader = new FileReader();
      filereader.addEventListener("load", () => {
        this.cardData.mediaUrl = filereader.result;
        this.cardData.mediaType = file.type.split("/")[0];
        this.sendFile();
      });
      filereader.readAsDataURL(file);
      this.image = file;

      try {
        const [{ _id, url }] = await this.filesUpload({ fileData: this.image });
        this.cardData.mediaId = _id;
        this.cardData.mediaUrl = url;
        this.imageLoading = false;
      } catch (err) {
        console.log(err);
      }
      // this.uploadLogo();
    }
  },
  watch: {
    $v: {
      deep: true,
      handler(value) {
        this.$emit("formInvalid", value.$invalid);
      }
    }
    // this.snackbar = true;
  }
};
</script>

<style scoped>
.links-card {
  box-shadow: 0px 0px 75px #00000024;
}
.image-container {
  max-height: 180px;
}

.text-container {
  width: 90%;
}
.file-input {
  padding-bottom: 130px;

  position: absolute;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;

  padding: 12px 55px;
  top: 187px;
  right: 23px;
  cursor: pointer;
  position: absolute;
}
</style>
